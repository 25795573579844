import React from "react";
import NavBar from "./NavBar";
import Footer from "./Footer";
import emailjs from "@emailjs/browser";
import Map from "./Map";
import Email from "../images/contact/email.png";
import Phone from "../images/contact/phone.png";
import cellPhone from "../images/contact/cellPhone.png";
import cellPhone1 from "../images/contact/cellphone1.png";
import Address from "../images/contact/address.png";
import { Helmet } from "react-helmet-async";

export default function Contact() {
  const sendEmail = (e) => {
    e.preventDefault();

    console.log(process.env.REACT_APP_PUBLIC_KEY);

    emailjs.sendForm(
      process.env.REACT_APP_SERVICE_ID,
      process.env.REACT_APP_TEMPLATE_ID,
      e.target,
      process.env.REACT_APP_PUBLIC_KEY
    );

    alert("Your message has been sent, we will get back to you shorty.");

    e.target.reset();
  };

  return (
    <>
      <Helmet>
        <title>Contact Information</title>
        <meta name="description" content="Contact Us" />
        <link rel="canonical" href="/contact" />
      </Helmet>
      <div className="flex flex-col bg-background bg-fixed bg-no-repeat bg-cover w-screen">
        <NavBar />
        {/* <h1 className="font-BankGothicMDBT items-center mt-36 text-center font-bankgothic sm:text-5xl md:text-7xl  lg:text-7xl xl:text-7xl text-white ">
          Send us an Inquiry
        </h1> */}

        <div className="flex flex-col mt-28 text-center pb-10 justify-center">
          {/* contact form */}
          {/* <form
            onSubmit={sendEmail}
            class="flex flex-col sm:mx-[5%] md:mx-[20%] lg:mx-[30%] mb-10 md:flex-row lg:flex-row m-auto md:max-h-[550px] lg:max-h-[550px] md:min-h-[600px] lg:min-h-[600px] items-center align-items-center border rounded-lg shadow-lg p-4"
          >
            <div class="flex flex-wrap mx-3 mb-6">
              <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <label
                  class="block uppercase tracking-wide text-white text-xl mb-2"
                  for="grid-first-name"
                >
                  First Name
                </label>
                <input
                  class="appearance-none block w-full bg-gray-200 text-gray-700 border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                  name="firstName"
                  id="grid-first-name"
                  type="text"
                  placeholder="John"
                  required
                ></input>
              </div>

              <div class="w-full md:w-1/2 px-3 mb-6 ">
                <label
                  class="block uppercase tracking-wide text-white text-xl mb-2"
                  for="grid-last-name"
                >
                  Last Name
                </label>
                <input
                  class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  name="lastName"
                  id="grid-last-name"
                  type="text"
                  placeholder="Doe"
                  required
                ></input>
              </div>

              <div class="w-full md:w-1/2 px-3 mb-6 ">
                <label
                  class="block uppercase tracking-wide text-white text-xl mb-2"
                  for="grid-last-name"
                >
                  Email Address
                </label>
                <input
                  class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  name="emailFrom"
                  id="emailFrom"
                  type="text"
                  placeholder="john@email.com"
                  required
                ></input>
              </div>

              <div class="w-full md:w-1/2 px-3 mb-6">
                <label
                  class="block uppercase tracking-wide text-white text-xl  mb-2"
                  for="grid-phone"
                >
                  Phone Number (optional)
                </label>
                <input
                  class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  name="phone"
                  id="grid-phone"
                  type="phone"
                  placeholder="(403)-123-4567"
                ></input>
              </div>

              <div class="w-full md:w-1/2 px-3 mb-6 ">
                <label
                  class="block uppercase tracking-wide text-white text-xl mb-2"
                  for="grid-last-name"
                >
                  Drop off time
                </label>
                <select
                  class=" block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  name="time"
                  id="emailFrom"
                  type="text"
                  placeholder="john@email.com"
                  required
                >
                  <option>Day</option>
                  <option>Night</option>
                </select>
              </div>

              <div class="w-full md:w-1/2 px-3 mb-6">
                <label
                  class="block uppercase tracking-wide text-white text-xl  mb-2"
                  for="grid-phone"
                >
                  License Plate #
                </label>
                <input
                  class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  name="license"
                  id="grid-phone"
                  type="text"
                  placeholder="AAA-0000"
                ></input>
              </div>

              <div class="w-full md:w-1/2 px-3 flex flex-col items-center min-w-full max-w-md">
                <label
                  class="block uppercase tracking-wide text-white text-xl mb-2"
                  for="grid-vehicle"
                >
                  Message (Vehicle make & model, description of the work you
                  need done)
                </label>
                <textarea
                  class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  name="message"
                  id="grid-vehicle"
                  type="text"
                  placeholder="2001 Volkswagen Jetta. Need oil change and break pads."
                  required
                ></textarea>
              </div>

              <div className="flex flex-col  mt-10 w-full items-center ">
                <button
                  type="submit"
                  class="bg-black-rgba text-4xl text-white font-bold py-2 px-2 drop-shadow-lg border border-white rounded hover:scale-125 hover:brightness-75 ease-in duration-300"
                >
                  Send
                </button>
              </div>
            </div>
          </form> */}

          {/* contact card */}
          <section className="min-h-[800px]">
            <div className="flex flex-col text-center sm:mx-[5%] md:mx-[25%] lg:mx-[30%]  items-center justify-center  p-4">
              <h3 className="text-4xl md:text-5xl lg:text-7xl text-white pb-10 font-BankGothicMDBT">
                Contact Information
              </h3>
              <div>
                <div className="pb-8 flex items-center">
                  <img className="rounded-lg p-2 w-[50px]" src={Email}></img>
                  <p className="text-white font-times text-xl">
                    <a href="mailto:rjautoanddiesel@gmail.com">
                      rjautoanddiesel@gmail.com
                    </a>
                  </p>
                </div>

                <div className="pb-8 flex items-center">
                  <img className="rounded-lg p-2" src={Phone}></img>
                  <p className="text-white font-times text-xl">
                    Call: <a href="tel:+14034520580">(403) 452-0580</a>
                  </p>
                </div>

                <div className="pb-8 flex items-center">
                  <img
                    className="rounded-lg p-2 w-[60px]"
                    src={cellPhone1}
                  ></img>
                  <p className="text-white font-times text-xl">
                    Text: <a href="tel:+14037015906">(403) 701-5906</a>
                  </p>
                </div>

                <div className="pb-8 flex items-center">
                  <img className="rounded-lg p-2" src={Address}></img>
                  <p className="text-white font-times text-xl">
                    Unit#108 1120 53AVE NE.<br></br>Calgary, AB, Canada<br></br>
                    T2E 6N9
                  </p>
                </div>
              </div>

              <div className="pb-10">
                <h4 className="text-white font-BankGothicMDBT text-2xl lg:text-2xl pb-2">
                  Hours of Operation
                </h4>
                <p className="text-white font-times text-xl pt-4">
                  Monday - Friday: 8:30am - 4:30pm
                </p>
                <p className="text-white font-times text-xl pt-4">
                  Weekend and Holidays: closed
                </p>
                <p className="text-white font-times text-xl pt-4">
                  Secure after-hours drop-off and pick-up available, contact us
                  for more information!
                </p>
              </div>
            </div>
          </section>

          <div className="mb-[-40px]">
            <Map />
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
}
