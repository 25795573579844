import React from "react";
import { ReactComponent as InstaIcon } from "../images/InstaIcon.svg";
import { ReactComponent as FaceBookIcon } from "../images/FaceBookIcon.svg";
import { ReactComponent as LinkedInIcon } from "../images/LinkedInIcon.svg";

{
  /*<button className="lg:invisible"><InstaIcon/></button>
<button className="lg:invisible"><FaceBookIcon/></button>
<button className="lg:invisible"><LinkedInIcon/></button> */
}

export default function Footer() {
  return (
    <div className="z-10">
      <div className="flex bottom-0 relative w-[100%] border-black bg-white hidden md:block lg:block xl:block">
        <footer className="relative border-black max-h-[100px] p-6 text-[10px] md:text-lg lg:text-lg shadow  invisible md:visible lg:visible xl:visible">
          <nav className="border-black">
            <div className="flex flex-row mb-[100px] space-x-10 justify-center items-center drop-shadow-4xl text-center">
              <div className="flex flex-col drop-shadow-4xl text-center">
                <h1 className="font-BankGothicMDBT justify-center  font-bankgothic text-2xl  text-black duration-500 ">
                  RJ
                </h1>
                <h1 className="font-BankGothicMDBT underline justify-center text-xl  text-black ">
                  Auto and Diesel Repair ©
                </h1>
              </div>

              <div className="flex">
                <a href="https://www.instagram.com/rjautoanddiesel/">
                  <button className="">
                    <InstaIcon width="50" height="50" />
                  </button>
                </a>
              </div>

              <div className="flex">
                <a href="https://www.facebook.com/people/RJ-Auto-and-Diesel-Repair/100091764995291/?mibextid=LQQJ4d">
                  <button className="">
                    <FaceBookIcon width="50" height="50" />
                  </button>
                </a>
              </div>
            </div>
          </nav>
        </footer>
      </div>

      {/* Mobile Footer */}
      <div className="bottom-0 min-h-[70px] bg-white block md:hidden lg:hidden xl:hidden">
        <footer className="relative h-20 uppercase border-black font-BankGothicMDBT p-4 text-[10px] md:text-lg lg:text-lg p-4">
          <nav className="">
            <ul className="flex justify-evenly ">
              <div className="flex flex-col drop-shadow-4xl text-center">
                <h1 className="font-BankGothicMDBT justify-center  font-bankgothic text-xl  text-black duration-500 ">
                  RJ
                </h1>
                <h1 className="font-BankGothicMDBT underline justify-center font-bankgothic  text-black ">
                  Auto and Diesel Repair
                </h1>
              </div>
              <li>
                <a href="https://www.instagram.com/rjautoanddiesel/">
                  <button className="lg:invisible ">
                    <InstaIcon width="40" height="40" />
                  </button>
                </a>
              </li>
              <li>
                <a href="https://www.facebook.com/people/RJ-Auto-and-Diesel-Repair/100091764995291/?mibextid=LQQJ4d">
                  <button className="lg:invisible">
                    <FaceBookIcon width="40" height="40" />
                  </button>
                </a>
              </li>
            </ul>
          </nav>
        </footer>
      </div>
    </div>
  );
}
