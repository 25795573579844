import React from "react";
import NavBar from "./NavBar";
import Footer from "./Footer";
import Checklist from "../images/services/checklist.png";
import Manitenance from "../images/services/maintenance.png";
import Diagnosis from "../images/services/searching.png";
import Schedule from "../images/services/car.png";
import Engine from "../images/services/car-engine.png";
import Van from "../images/services/van.png";
import Suspension from "../images/services/suspension.png";
import Air from "../images/services/air-conditioning.png";
import Tires from "../images/services/tires.png";
import Wheels from "../images/services/wheels.png";
import Windshields from "../images/services/windscreen.png";
import Camera from "../images/services/camera.png";
import { Helmet } from "react-helmet-async";

export default function Services() {
  return (
    <>
      <Helmet>
        <title>Services</title>
        <meta
          name="description"
          content="We offer a wide variety of auto services on all makes and models!"
        />
        <link rel="canonical" href="/services" />
      </Helmet>
      <div className="flex flex-col bg-background bg-fixed bg-cover">
        <NavBar />
        <div className="flex flex-col text-center items-center">
          <h1 className="font-BankGothicMDBT items-center mb-24 mt-36 font-bankgothic text-7xl text-white ">
            Services
          </h1>

          <div className="flex flex-wrap justify-center sm:mx-[5%] lg:mx-[10%] xl:mx-[20%] pb-24">
            <div
              data-aos="fade-down"
              className="flex p-4 rounded-lg sm:w-[300px] md:w-[450px] lg:w-[450px] xl:w-[450px] shadow-sm shadow-white flex-col bg-black items-center sm:mx-0 md:mx-24 lg:mx-24 xl:mx-24 my-20 lg:my-20 xl:my-10"
            >
              <h1 className="font-BankGothicMDBT text-center text-white justify-center  font-bankgothic text-3xl pb-4">
                Inspections
              </h1>
              <img
                className="h-[150px] w-[150px] bg-gray-400 rounded-lg p-2"
                src={Checklist}
              ></img>
              <h1 className="text-left justify-center text-white font-times sm:text-xl md:text-2xl lg:text-2xl xl:text-2xl pt-4">
                &#x2022; Insurance and pre-purchase
                <br></br>
                &#x2022; Basic safety inspections
              </h1>
            </div>

            <div
              data-aos="fade-down"
              className="flex  p-4 rounded-lg sm:w-[300px] md:w-[450px] lg:w-[450px] xl:w-[450px] shadow-sm shadow-white flex-col bg-black items-center sm:mx-0 md:mx-24 lg:mx-24 xl:mx-24 my-20 lg:my-20 xl:my-10"
            >
              <h1 className="font-BankGothicMDBT text-center text-white justify-center  font-bankgothic text-3xl  pb-4">
                Digital Inspections
              </h1>
              <img
                className="h-[150px] w-[150px] bg-gray-400 rounded-lg p-2"
                src={Camera}
              ></img>
              <h1 className="text-left justify-center text-white font-times sm:text-xl md:text-2xl lg:text-2xl xl:text-2xl pt-4">
                &#x2022; Photos/videos of minor and major issues
                <br></br>
                &#x2022; Photo of vehicle with all info (VIN, odometer, license
                number)
                <br></br>
                &#x2022; Inspections emailed to customer
              </h1>
            </div>

            <div
              data-aos="fade-down"
              className="flex  p-4 rounded-lg sm:w-[300px] md:w-[450px] lg:w-[450px] xl:w-[450px] shadow-sm shadow-white flex-col bg-black items-center sm:mx-0 md:mx-24 lg:mx-24 xl:mx-24 my-20 lg:my-20 xl:my-10"
            >
              <h1 className="font-BankGothicMDBT text-center text-white justify-center  font-bankgothic text-3xl pb-4">
                Bumper to bumper service
              </h1>
              <img
                className="h-[150px] w-[150px] bg-gray-400 rounded-lg p-2"
                src={Manitenance}
              ></img>
              <h1 className="text-left justify-center text-white font-times sm:text-xl md:text-2xl lg:text-2xl xl:text-2xl pt-4">
                &#x2022; All makes and models
                <br></br>
                &#x2022; Compact car to 5500 series truck
              </h1>
            </div>

            <div
              data-aos="fade-down"
              className="flex  p-4 rounded-lg sm:w-[300px] md:w-[450px] lg:w-[450px] xl:w-[450px] shadow-sm shadow-white flex-col bg-black items-center sm:mx-0 md:mx-24 lg:mx-24 xl:mx-24 my-20 lg:my-20 xl:my-10"
            >
              <h1 className="font-BankGothicMDBT text-center text-white justify-center  font-bankgothic text-3xl pb-4">
                Expert Diagnosis
              </h1>
              <img
                className="h-[150px] w-[150px] bg-gray-400 rounded-lg p-2"
                src={Diagnosis}
              ></img>
              <h1 className="text-left justify-center text-white font-times sm:text-xl md:text-2xl lg:text-2xl xl:text-2xl pt-4">
                &#x2022; Fast pin point diagnosis
                <br></br>
                &#x2022; Complex electrical diagnosis
                <br></br>
                &#x2022; Drivability, steering, suspension, driveline concerns,
                etc
              </h1>
            </div>

            <div
              data-aos="fade-down"
              className="flex  p-4 rounded-lg sm:w-[300px] md:w-[450px] lg:w-[450px] xl:w-[450px] shadow-sm shadow-white flex-col bg-black items-center sm:mx-0 md:mx-24 lg:mx-24 xl:mx-24 my-20 lg:my-20 xl:my-10"
            >
              <h1 className="font-BankGothicMDBT text-center text-white justify-center  font-bankgothic text-3xl pb-4">
                Complete Maintenance
              </h1>
              <img
                className="h-[150px] w-[150px] bg-gray-400 rounded-lg p-2"
                src={Schedule}
              ></img>
              <h1 className="text-left justify-center text-white font-times sm:text-xl md:text-2xl lg:text-2xl xl:text-2xl pt-4">
                &#x2022; Following manufacturers maintenance schedules
                <br></br>
                &#x2022; Ensure you are informed and up-to-date
              </h1>
            </div>

            <div
              data-aos="fade-down"
              className="flex p-4 rounded-lg sm:w-[300px] md:w-[450px] lg:w-[450px] xl:w-[450px] shadow-sm shadow-white flex-col bg-black items-center sm:mx-0 md:mx-24 lg:mx-24 xl:mx-24 my-20 lg:my-20 xl:my-10"
            >
              <h1 className="font-BankGothicMDBT text-center text-white justify-center  font-bankgothic text-3xl pb-4">
                Complex mechanical repairs
              </h1>
              <img
                className="h-[150px] w-[150px] bg-gray-400 rounded-lg p-2"
                src={Engine}
              ></img>
              <h1 className="text-left justify-center text-white font-times sm:text-xl md:text-2xl lg:text-2xl xl:text-2xl pt-4 pb-4">
                &#x2022; Engines
                <br></br>
                &#x2022; Transmissions
                <br></br>
                &#x2022; Differentials
              </h1>
            </div>

            <div
              data-aos="fade-down"
              className="flex  p-4 rounded-lg sm:w-[300px] md:w-[450px] lg:w-[450px] xl:w-[450px] shadow-sm shadow-white flex-col bg-black items-center sm:mx-0 md:mx-24 lg:mx-24 xl:mx-24 my-20 lg:my-20 xl:my-10"
            >
              <h1 className="font-BankGothicMDBT  text-center text-white justify-center  font-bankgothic text-3xl  pb-4">
                Steering and suspension repairs
              </h1>
              <img
                className="h-[150px] w-[150px] bg-gray-400 rounded-lg p-2"
                src={Suspension}
              ></img>
            </div>

            <div
              data-aos="fade-down"
              className="flex  p-4 rounded-lg sm:w-[300px] md:w-[450px] lg:w-[450px] xl:w-[450px] shadow-sm shadow-white flex-col bg-black items-center sm:mx-0 md:mx-24 lg:mx-24 xl:mx-24 my-20 lg:my-20 xl:my-10"
            >
              <h1 className="font-BankGothicMDBT  text-center text-white justify-center  font-bankgothic text-3xl  pb-4">
                Heating and air conditioning
              </h1>
              <img
                className="h-[150px] w-[150px] bg-gray-400 rounded-lg p-2"
                src={Air}
              ></img>
            </div>

            <div
              data-aos="fade-down"
              className="flex  p-4 rounded-lg sm:w-[300px] md:w-[450px] lg:w-[450px] xl:w-[450px] shadow-sm shadow-white flex-col bg-black items-center sm:mx-0 md:mx-24 lg:mx-24 xl:mx-24 my-20 lg:my-20 xl:my-10"
            >
              <h1 className="font-BankGothicMDBT text-center text-white justify-center  font-bankgothic text-3xl  pb-4">
                Wheels and tires
              </h1>
              <img
                className="h-[150px] w-[150px] bg-gray-400 rounded-lg p-2"
                src={Tires}
              ></img>
              <h1 className="text-left justify-center text-white font-times sm:text-xl md:text-2xl lg:text-2xl xl:text-2xl pt-4">
                &#x2022; Up to 30"
              </h1>
            </div>

            <div
              data-aos="fade-down"
              className="flex  p-4 rounded-lg sm:w-[300px] md:w-[450px] lg:w-[450px] xl:w-[450px] shadow-sm shadow-white flex-col bg-black items-center sm:mx-0 md:mx-24 lg:mx-24 xl:mx-24 my-20 lg:my-20 xl:my-10"
            >
              <h1 className="font-BankGothicMDBT  text-center text-white justify-center  font-bankgothic text-3xl  pb-4">
                Wheel alignment
              </h1>
              <img
                className="h-[150px] w-[150px] bg-gray-400 rounded-lg p-2"
                src={Wheels}
              ></img>
              <h1 className="text-left justify-center text-white font-times sm:text-xl md:text-2xl lg:text-2xl xl:text-2xl pt-4">
                &#x2022; Including dually's
              </h1>
            </div>

            <div
              data-aos="fade-down"
              className="flex  p-4 rounded-lg sm:w-[300px] md:w-[450px] lg:w-[450px] xl:w-[450px] shadow-sm shadow-white flex-col bg-black items-center sm:mx-0 md:mx-24 lg:mx-24 xl:mx-24 my-20 lg:my-20 xl:my-10"
            >
              <h1 className="font-BankGothicMDBT text-center text-white justify-center  font-bankgothic text-3xl  pb-4">
                Windshields
              </h1>
              <img
                className="h-[150px] w-[150px] bg-gray-400 rounded-lg p-2"
                src={Windshields}
              ></img>
              <h1 className="text-left justify-center text-white font-times sm:text-xl md:text-2xl lg:text-2xl xl:text-2xl pt-4">
                &#x2022; Chip repair
                <br></br>
                &#x2022; Replacement
              </h1>
            </div>

            <div
              data-aos="fade-down"
              className="flex text-center p-4 rounded-lg  shadow-sm shadow-white flex-col bg-black items-center sm:mx-0 md:mx-24 lg:mx-24 xl:mx-24 my-20 lg:my-20 xl:my-10"
            >
              <h1 className="font-BankGothicMDBT  text-center text-white justify-center  font-bankgothic text-3xl  pb-4">
                Fleet Service
              </h1>
              <img
                className="h-[150px] w-[150px] bg-gray-400 rounded-lg p-2"
                src={Van}
              ></img>
              <h1 className="text-center justify-center text-white font-times sm:text-xl md:text-2xl lg:text-2xl xl:text-2xl pt-4">
                We accommodate commercial fleet vehicles and have designed our
                shop to be able to work on cube vans, larger delivery vehicles,
                landscaping trucks, and all other tradesman vehicles up to 5500
                series trucks. We are not scared to dive right into a cab off
                diesel engine replacement or repair and have an abundance of
                experience doing so.
              </h1>
            </div>
          </div>

          <div className="pb-10 sm:mx-[5%] md:mx-[15%] lg:mx-[15%] xl:mx-[20%] mb-24">
            <h4 className="text-white font-BankGothicMDBT text-2xl leading-5 md:text-4xl lg:text-4xl xl:text-4xl pb-2 underline">
              On a tight budget?
            </h4>
            <p className="text-white font-times text-2xl pt-4">
              We will always try to move the earth to get you back on the road,
              even if that means sourcing out used parts to get you going.
            </p>
          </div>

          <div className="pb-10 sm:mx-[5%] md:mx-[15%] lg:mx-[15%] xl:mx-[20%] mb-24">
            <h4 className="text-white font-BankGothicMDBT text-2xl leading-5 md:text-4xl lg:text-4xl xl:text-4xl pb-2 underline">
              Having a weird problem that other shops can’t figure out?{" "}
            </h4>
            <p className="text-white font-times text-2xl pt-4">
              We love digging right in and will not stop until the problem has
              been found, even if that means sacrificing some of our own time.
              Never hesitate to contact us with issues like this, we are always
              happy to help.
            </p>
          </div>

          <div className="pb-10 sm:mx-[5%] md:mx-[15%] lg:mx-[15%] xl:mx-[20%] mb-24">
            <h4 className="text-white font-BankGothicMDBT text-2xl leading-5 md:text-4xl lg:text-4xl xl:text-4xl pb-2 underline">
              Require a service but need to wait?
            </h4>
            <p className="text-white font-times text-2xl pt-4">
              No problem, we have a comfortable, cozy waiting room and provide a
              variety of refreshments with free WIFI and a TV.{" "}
            </p>
          </div>

          <div className="pb-10 sm:mx-[5%] md:mx-[15%] lg:mx-[15%] xl:mx-[20%] mb-24">
            <h4 className="text-white font-BankGothicMDBT text-2xl leading-5 md:text-4xl lg:text-4xl xl:text-4xl pb-2 underline">
              My vehicle is under warranty, do I have to go to the dealer?
            </h4>
            <p className="text-white font-times text-2xl pt-4">
              No, you don’t! We are a warranty approved service facility and can
              do any maintenance service while with holding factory warranty.
            </p>
          </div>

          <h1 className="font-BankGothicMDBT items-center mb-7 mt-36 font-bankgothic sm:text-5xl md:text-7xl lg:text-7xl xl:text-7xl text-white ">
            Warranty
          </h1>
          <h1 className=" items-center mb-28 mx-[10%] md:mx-[30%] lg:mx-[30%] xl:mx-[30%]  font-times  sm:text-2xl md:text-2xl lg:text-2xl xl:text-2xl font-BerlinSansFBDemiBold xl:sm:text-xl md:text-2xl lg:text-2xl xl:text-2xl font-BerlinSansFBDemiBold text-white ">
            All repairs performed at RJ Auto and Diesel Repair will recieve a 1
            year 20,000km warranty. There will be no warranty provided on
            customer provided parts, which is why customer provided parts are
            seldom accepted.
          </h1>
        </div>
        <Footer />
      </div>
    </>
  );
}
