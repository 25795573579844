import React from "react";
import NavBar from "./NavBar";
import Footer from "./Footer";
import Sign from "../images/gallery/sign.jpg";
import Painting1 from "../images/gallery/painting1.jpg";
import Painting2 from "../images/gallery/painting2.jpg";
import Paintfinish from "../images/gallery/paintfinish.jpg";
import Shop from "../images/gallery/shop.jpg";
import Floorplan from "../images/gallery/floorplan.jpg";
import Floordig from "../images/gallery/floordig.jpg";
import Concrete from "../images/gallery/concrete.jpg";
import Piping from "../images/gallery/piping.jpg";
import Dirtfill from "../images/gallery/dirtfill.jpg";
import Grate from "../images/gallery/grate.jpg";
import Ventout from "../images/gallery/ventoutside.jpg";
import Ventin from "../images/gallery/ventinside.jpg";
import Preprep from "../images/gallery/preprep.jpg";
import CarLift1 from "../images/gallery/CarLift1.jpg";
import CarLift2 from "../images/gallery/CarLift2.jpg";
import Office1 from "../images/gallery/Office1.jpg";
import Office2 from "../images/gallery/Office2.jpg";
import { Helmet } from "react-helmet-async";

import pic1 from "../images/gallery/1.jpg";
import pic2 from "../images/gallery/2.jpg";
import pic3 from "../images/gallery/3.jpg";
import pic4 from "../images/gallery/4.jpg";
import pic5 from "../images/gallery/5.jpg";
import pic6 from "../images/gallery/6.jpg";
import pic7 from "../images/gallery/7.jpg";
import pic8 from "../images/gallery/8.jpg";
import pic9 from "../images/gallery/9.jpg";
import pic10 from "../images/gallery/10.jpg";
import pic11 from "../images/gallery/11.jpg";
import pic12 from "../images/gallery/12.jpg";

export default function Gallery() {
  return (
    <>
      <Helmet>
        <title>Gallery</title>
        <meta name="description" content="Our Shop Gallery" />
        <link rel="canonical" href="/gallery" />
      </Helmet>

      <div className="flex flex-col bg-background bg-fixed bg-cover">
        <NavBar />
        <div className="flex flex-col items-center justify-center text-center">
          <h1 className="font-BankGothicMDBT items-center mb-7 mt-36 font-bankgothic text-7xl text-white ">
            Gallery
          </h1>

          <div className="flex flex-wrap justify-center items-center sm:mx-[5%] md:mx-[10%] lg:mx-[10%] xl:mx-[20%] pb-24">
            <div className=" flex">
              <img
                src={Sign}
                className="sm:h-[350px] sm:min-w-[300px] sm:max-w-[300px] lg:max-w-[500px] lg:h-[500px] lg:w-[500px] md:h-[400px] md:w-[350px] md:max-w-[350px]  border rounded-lg sm:mx-0 md:mx-24 lg:mx-24 xl:mx-24 my-24 transform transition-all md:hover:scale-150 lg:hover:scale-150 xl:hover:scale-150"
              ></img>
            </div>

            <div className=" flex">
              <img
                src={Preprep}
                className="sm:h-[350px] sm:min-w-[300px] sm:max-w-[300px] lg:max-w-[500px] lg:h-[500px] lg:w-[500px] md:h-[400px] md:w-[350px] md:max-w-[350px]  border rounded-lg sm:mx-0 md:mx-24 lg:mx-24 xl:mx-24 my-24 transform transition-all md:hover:scale-150 lg:hover:scale-150 xl:hover:scale-150"
              ></img>
            </div>

            <div className=" flex">
              <img
                src={Painting1}
                className="sm:h-[350px] sm:min-w-[300px] sm:max-w-[300px] lg:max-w-[500px] lg:h-[500px] lg:w-[500px] md:h-[400px] md:w-[350px] md:max-w-[350px]  border rounded-lg sm:mx-0 md:mx-24 lg:mx-24 xl:mx-24 my-24 transform transition-all md:hover:scale-150 lg:hover:scale-150 xl:hover:scale-150"
              ></img>
            </div>

            <div className=" flex">
              <img
                src={Painting2}
                className="sm:h-[350px] sm:min-w-[300px] sm:max-w-[300px] lg:max-w-[500px] lg:h-[500px] lg:w-[500px] md:h-[400px] md:w-[350px] md:max-w-[350px]  border rounded-lg sm:mx-0 md:mx-24 lg:mx-24 xl:mx-24 my-24 transform transition-all md:hover:scale-150 lg:hover:scale-150 xl:hover:scale-150"
              ></img>
            </div>

            <div className=" flex">
              <img
                src={Paintfinish}
                className="sm:h-[350px] sm:min-w-[300px] sm:max-w-[300px] lg:max-w-[500px] lg:h-[500px] lg:w-[500px] md:h-[400px] md:w-[350px] md:max-w-[350px]  border rounded-lg sm:mx-0 md:mx-24 lg:mx-24 xl:mx-24 my-24 transform transition-all md:hover:scale-150 lg:hover:scale-150 xl:hover:scale-150"
              ></img>
            </div>

            <div className=" flex">
              <img
                src={Shop}
                className="sm:h-[350px] sm:min-w-[300px] sm:max-w-[300px] lg:max-w-[500px] lg:h-[500px] lg:w-[500px] md:h-[400px] md:w-[350px] md:max-w-[350px]  border rounded-lg sm:mx-0 md:mx-24 lg:mx-24 xl:mx-24 my-24 transform transition-all md:hover:scale-150 lg:hover:scale-150 xl:hover:scale-150"
              ></img>
            </div>

            <div className=" flex">
              <img
                src={Floorplan}
                className="sm:h-[350px] sm:min-w-[300px] sm:max-w-[300px] lg:max-w-[500px] lg:h-[500px] lg:w-[500px] md:h-[400px] md:w-[350px] md:max-w-[350px]  border rounded-lg sm:mx-0 md:mx-24 lg:mx-24 xl:mx-24 my-24 transform transition-all md:hover:scale-150 lg:hover:scale-150 xl:hover:scale-150"
              ></img>
            </div>

            <div className=" flex">
              <img
                src={Floordig}
                className="sm:h-[350px] sm:min-w-[300px] sm:max-w-[300px] lg:max-w-[500px] lg:h-[500px] lg:w-[500px] md:h-[400px] md:w-[350px] md:max-w-[350px]  border rounded-lg sm:mx-0 md:mx-24 lg:mx-24 xl:mx-24 my-24 transform transition-all md:hover:scale-150 lg:hover:scale-150 xl:hover:scale-150"
              ></img>
            </div>

            <div className=" flex">
              <img
                src={Concrete}
                className="sm:h-[350px] sm:min-w-[300px] sm:max-w-[300px] lg:max-w-[500px] lg:h-[500px] lg:w-[500px] md:h-[400px] md:w-[350px] md:max-w-[350px]  border rounded-lg sm:mx-0 md:mx-24 lg:mx-24 xl:mx-24 my-24 transform transition-all md:hover:scale-150 lg:hover:scale-150 xl:hover:scale-150"
              ></img>
            </div>

            <div className=" flex">
              <img
                src={Piping}
                className="sm:h-[350px] sm:min-w-[300px] sm:max-w-[300px] lg:max-w-[500px] lg:h-[500px] lg:w-[500px] md:h-[400px] md:w-[350px] md:max-w-[350px]  border rounded-lg sm:mx-0 md:mx-24 lg:mx-24 xl:mx-24 my-24 transform transition-all md:hover:scale-150 lg:hover:scale-150 xl:hover:scale-150"
              ></img>
            </div>

            <div className=" flex">
              <img
                src={Dirtfill}
                className="sm:h-[350px] sm:min-w-[300px] sm:max-w-[300px] lg:max-w-[500px] lg:h-[500px] lg:w-[500px] md:h-[400px] md:w-[350px] md:max-w-[350px]  border rounded-lg sm:mx-0 md:mx-24 lg:mx-24 xl:mx-24 my-24 transform transition-all md:hover:scale-150 lg:hover:scale-150 xl:hover:scale-150"
              ></img>
            </div>

            <div className=" flex">
              <img
                src={Grate}
                className="sm:h-[350px] sm:min-w-[300px] sm:max-w-[300px] lg:max-w-[500px] lg:h-[500px] lg:w-[500px] md:h-[400px] md:w-[350px] md:max-w-[350px]  border rounded-lg sm:mx-0 md:mx-24 lg:mx-24 xl:mx-24 my-24 transform transition-all md:hover:scale-150 lg:hover:scale-150 xl:hover:scale-150"
              ></img>
            </div>

            <div className=" flex">
              <img
                src={Ventout}
                className="sm:h-[350px] sm:min-w-[300px] sm:max-w-[300px] lg:max-w-[500px] lg:h-[500px] lg:w-[500px] md:h-[400px] md:w-[350px] md:max-w-[350px]  border rounded-lg sm:mx-0 md:mx-24 lg:mx-24 xl:mx-24 my-24 transform transition-all md:hover:scale-150 lg:hover:scale-150 xl:hover:scale-150"
              ></img>
            </div>

            <div className=" flex">
              <img
                src={Ventin}
                className="sm:h-[350px] sm:min-w-[300px] sm:max-w-[300px] lg:max-w-[500px] lg:h-[500px] lg:w-[500px] md:h-[400px] md:w-[350px] md:max-w-[350px]  border rounded-lg sm:mx-0 md:mx-24 lg:mx-24 xl:mx-24 my-24 transform transition-all md:hover:scale-150 lg:hover:scale-150 xl:hover:scale-150"
              ></img>
            </div>

            <div className=" flex">
              <img
                src={pic1}
                className="sm:h-[350px] sm:min-w-[300px] sm:max-w-[300px] lg:max-w-[500px] lg:h-[500px] lg:w-[500px] md:h-[400px] md:w-[350px] md:max-w-[350px]  border rounded-lg sm:mx-0 md:mx-24 lg:mx-24 xl:mx-24 my-24 transform transition-all md:hover:scale-150 lg:hover:scale-150 xl:hover:scale-150"
              ></img>
            </div>

            <div className=" flex">
              <img
                src={pic2}
                className="sm:h-[350px] sm:min-w-[300px] sm:max-w-[300px] lg:max-w-[500px] lg:h-[500px] lg:w-[500px] md:h-[400px] md:w-[350px] md:max-w-[350px]  border rounded-lg sm:mx-0 md:mx-24 lg:mx-24 xl:mx-24 my-24 transform transition-all md:hover:scale-150 lg:hover:scale-150 xl:hover:scale-150"
              ></img>
            </div>

            <div className=" flex">
              <img
                src={pic3}
                className="sm:h-[350px] sm:min-w-[300px] sm:max-w-[300px] lg:max-w-[500px] lg:h-[500px] lg:w-[500px] md:h-[400px] md:w-[350px] md:max-w-[350px]  border rounded-lg sm:mx-0 md:mx-24 lg:mx-24 xl:mx-24 my-24 transform transition-all md:hover:scale-150 lg:hover:scale-150 xl:hover:scale-150"
              ></img>
            </div>

            <div className=" flex">
              <img
                src={pic4}
                className="sm:h-[350px] sm:min-w-[300px] sm:max-w-[300px] lg:max-w-[500px] lg:h-[500px] lg:w-[500px] md:h-[400px] md:w-[350px] md:max-w-[350px]  border rounded-lg sm:mx-0 md:mx-24 lg:mx-24 xl:mx-24 my-24 transform transition-all md:hover:scale-150 lg:hover:scale-150 xl:hover:scale-150"
              ></img>
            </div>

            <div className=" flex">
              <img
                src={pic5}
                className="sm:h-[350px] sm:min-w-[300px] sm:max-w-[300px] lg:max-w-[500px] lg:h-[500px] lg:w-[500px] md:h-[400px] md:w-[350px] md:max-w-[350px]  border rounded-lg sm:mx-0 md:mx-24 lg:mx-24 xl:mx-24 my-24 transform transition-all md:hover:scale-150 lg:hover:scale-150 xl:hover:scale-150"
              ></img>
            </div>

            <div className=" flex">
              <img
                src={pic6}
                className="sm:h-[350px] sm:min-w-[300px] sm:max-w-[300px] lg:max-w-[500px] lg:h-[500px] lg:w-[500px] md:h-[400px] md:w-[350px] md:max-w-[350px]  border rounded-lg sm:mx-0 md:mx-24 lg:mx-24 xl:mx-24 my-24 transform transition-all md:hover:scale-150 lg:hover:scale-150 xl:hover:scale-150"
              ></img>
            </div>

            <div className=" flex">
              <img
                src={pic7}
                className="sm:h-[350px] sm:min-w-[300px] sm:max-w-[300px] lg:max-w-[500px] lg:h-[500px] lg:w-[500px] md:h-[400px] md:w-[350px] md:max-w-[350px]  border rounded-lg sm:mx-0 md:mx-24 lg:mx-24 xl:mx-24 my-24 transform transition-all md:hover:scale-150 lg:hover:scale-150 xl:hover:scale-150"
              ></img>
            </div>

            <div className=" flex">
              <img
                src={pic8}
                className="sm:h-[350px] sm:min-w-[300px] sm:max-w-[300px] lg:max-w-[500px] lg:h-[500px] lg:w-[500px] md:h-[400px] md:w-[350px] md:max-w-[350px]  border rounded-lg sm:mx-0 md:mx-24 lg:mx-24 xl:mx-24 my-24 transform transition-all md:hover:scale-150 lg:hover:scale-150 xl:hover:scale-150"
              ></img>
            </div>

            <div className=" flex">
              <img
                src={pic9}
                className="sm:h-[350px] sm:min-w-[300px] sm:max-w-[300px] lg:max-w-[500px] lg:h-[500px] lg:w-[500px] md:h-[400px] md:w-[350px] md:max-w-[350px]  border rounded-lg sm:mx-0 md:mx-24 lg:mx-24 xl:mx-24 my-24 transform transition-all md:hover:scale-150 lg:hover:scale-150 xl:hover:scale-150"
              ></img>
            </div>

            <div className=" flex">
              <img
                src={pic10}
                className="sm:h-[350px] sm:min-w-[300px] sm:max-w-[300px] lg:max-w-[500px] lg:h-[500px] lg:w-[500px] md:h-[400px] md:w-[350px] md:max-w-[350px]  border rounded-lg sm:mx-0 md:mx-24 lg:mx-24 xl:mx-24 my-24 transform transition-all md:hover:scale-150 lg:hover:scale-150 xl:hover:scale-150"
              ></img>
            </div>

            <div className=" flex">
              <img
                src={pic11}
                className="sm:h-[350px] sm:min-w-[300px] sm:max-w-[300px] lg:max-w-[500px] lg:h-[500px] lg:w-[500px] md:h-[400px] md:w-[350px] md:max-w-[350px]  border rounded-lg sm:mx-0 md:mx-24 lg:mx-24 xl:mx-24 my-24 transform transition-all md:hover:scale-150 lg:hover:scale-150 xl:hover:scale-150"
              ></img>
            </div>

            <div className=" flex">
              <img
                src={pic12}
                className="sm:h-[350px] sm:min-w-[300px] sm:max-w-[300px] lg:max-w-[500px] lg:h-[500px] lg:w-[500px] md:h-[400px] md:w-[350px] md:max-w-[350px]  border rounded-lg sm:mx-0 md:mx-24 lg:mx-24 xl:mx-24 my-24 transform transition-all md:hover:scale-150 lg:hover:scale-150 xl:hover:scale-150"
              ></img>
            </div>

            <div className=" flex">
              <img
                src={CarLift1}
                className="sm:h-[350px] sm:min-w-[300px] sm:max-w-[300px] lg:max-w-[500px] lg:h-[500px] lg:w-[500px] md:h-[400px] md:w-[350px] md:max-w-[350px]  border rounded-lg sm:mx-0 md:mx-24 lg:mx-24 xl:mx-24 my-24 transform transition-all md:hover:scale-150 lg:hover:scale-150 xl:hover:scale-150"
              ></img>
            </div>

            <div className=" flex">
              <img
                src={CarLift2}
                className="sm:h-[350px] sm:min-w-[300px] sm:max-w-[300px] lg:max-w-[500px] lg:h-[500px] lg:w-[500px] md:h-[400px] md:w-[350px] md:max-w-[350px]  border rounded-lg sm:mx-0 md:mx-24 lg:mx-24 xl:mx-24 my-24 transform transition-all md:hover:scale-150 lg:hover:scale-150 xl:hover:scale-150"
              ></img>
            </div>

            <div className=" flex">
              <img
                src={Office1}
                className="sm:h-[350px] sm:min-w-[300px] sm:max-w-[300px] lg:max-w-[500px] lg:h-[500px] lg:w-[500px] md:h-[400px] md:w-[350px] md:max-w-[350px]  border rounded-lg sm:mx-0 md:mx-24 lg:mx-24 xl:mx-24 my-24 transform transition-all md:hover:scale-150 lg:hover:scale-150 xl:hover:scale-150"
              ></img>
            </div>

            <div className=" flex">
              <img
                src={Office2}
                className="sm:h-[350px] sm:min-w-[300px] sm:max-w-[300px] lg:max-w-[500px] lg:h-[500px] lg:w-[500px] md:h-[400px] md:w-[350px] md:max-w-[350px]  border rounded-lg sm:mx-0 md:mx-24 lg:mx-24 xl:mx-24 my-24 transform transition-all md:hover:scale-150 lg:hover:scale-150 xl:hover:scale-150"
              ></img>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
