import React from "react";
import NavBar from "./NavBar";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import { useState, useCallback } from "react";
import "../App.css";
import Slideshow from "./Slider";
import LogoCarousel from "./LogoCarousel";
import { ReactComponent as InstaIcon } from "../images/InstaIcon.svg";
import { ReactComponent as FaceBookIcon } from "../images/FaceBookIcon.svg";
import { Helmet } from "react-helmet-async";

import Checklist from "../images/services/checklist.png";
import Manitenance from "../images/services/maintenance.png";
import Diagnosis from "../images/services/searching.png";
import Schedule from "../images/services/car.png";
import Engine from "../images/services/car-engine.png";
import Van from "../images/services/van.png";
import Suspension from "../images/services/suspension.png";
import Air from "../images/services/air-conditioning.png";
import Tires from "../images/services/tires.png";
import Wheels from "../images/services/wheels.png";
import Windshields from "../images/services/windscreen.png";
import Dashboard from "../images/dashboard.webp";

import { FacebookEmbed, InstagramEmbed } from "react-social-media-embed";

export default function Home() {
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);
  return (
    <>
      <Helmet>
        <title>RJ Auto and Diesel Home</title>
        <meta name="description" content="Auto Repair Shop" />
      </Helmet>
      <div className="flex flex-col overflow-x-hidden bg-background bg-fixed bg-cover bg-center">
        <NavBar />
        <section className="sm:min-h-[800px] md:min-h-[100vh] lg:min-h-[100vh] xl:min-h-[100vh]">
          <img
            className="sm:w-full sm:h-[250px] sm:border-b md:w-full md:h-full lg:w-full lg:h-full absolute sm:block md:block lg:block xl:block"
            src={Dashboard}
          ></img>
          <div className="flex flex-col">
            <div className="flex flex-col mt- sm:mt- md:mt-20 lg:mt-24 xl:mt-36 w-full items-center">
              <div
                data-aos="fade-down"
                className="flex flex-col z-40 absolute min-w-min  items-center "
              >
                <div className="mt-14 md:mt-14 lg:mt-24 xl:mt-24 rounded-lg text-center z-50">
                  <h1 className="font-BankGothicMDBT z-50  justify-center text-5xl md:text-8xl lg:text-9xl xl:text-9xl text-white duration-500 ">
                    RJ
                  </h1>
                  <h1 className="font-BankGothicMDBT underline justify-center sm:text-sm md:text-4xl lg:text-6xl text-white ">
                    Auto and Diesel Repair
                  </h1>
                </div>
              </div>
            </div>

            <div
              data-aos="fade-up"
              className="flex flex-col absolute mt-56 sm:mt-[350px] md:mt-[350px] lg:mt-[450px] xl:mt-[500px] pb-10 p-10 mx-0 min-w-full items-center"
            >
              <Link onClick={forceUpdate} to="/contact">
                <button class="bg-black-rgba text-3xl xl:text-5xl text-white font-bold py-4 px-4 drop-shadow-2xl border border-white rounded hover:scale-125 hover:brightness-75 ease-in duration-300 ">
                  Book Now!
                </button>
              </Link>
            </div>

            <div className="px-2 z-10 text-center sm:block md:hidden lg:hidden xl:hidden mt-[500px]">
              <h4 className="text-white font-BankGothicMDBT text-2xl leading-5">
                Hours of Operation
              </h4>
              <p className="text-white font-times text-xl pt-4">
                Monday - Friday: 8:30am - 4:30pm
              </p>
              <p className="text-white font-times text-xl pt-4">
                Weekend and Holidays: closed
              </p>
              <p className="text-white font-times text-xl pt-4">
                Secure after hours drop off and pickup available, contact us for
                more information!
              </p>
            </div>
          </div>
        </section>

        <section className=" md:mt-36 lg:mt-36 xl:mt-36 mb-10 z-10">
          <div className="z-10 flex flex-col text-center items-center sm:mx-[10%] md:mx-[15%] lg:mx-[15%] xl:mx-[15%]">
            <div className="pb-24 px-2 z-10 mt-10 text-center sm:hidden md:block lg:block xl:block">
              <h4 className="text-white font-BankGothicMDBT text-2xl leading-5 sm:text-5xl md:text-6xl lg:text-5xl xl:text-7xl pb-2">
                Hours of Operation
              </h4>
              <p className="text-white font-times text-2xl pt-4">
                Monday - Friday: 8:30am - 4:30pm
              </p>
              <p className="text-white font-times text-2xl pt-4">
                Weekend and Holidays: closed
              </p>
              <p className="text-white font-times text-2xl pt-4">
                Secure after hours drop-off and pick-up available, contact us
                for more information!
              </p>
            </div>

            <div className="flex flex-col justify-center lg:mx-[15%] xl:mx-[25%] pb-24">
              <h1 className="font-BankGothicMDBT items-center mb-7 font-bankgothic sm:text-4xl md:text-6xl lg:text-6xl xl:text-7xl text-white ">
                Why choose <br></br> Rj Auto and Diesel Repair?
              </h1>
              <h1 className="items-center font-times sm:text-xl md:text-2xl lg:text-2xl text-white ">
                At RJ, we perform vehicle maintenance and repairs in a timely
                manner on all makes and models. We understand vehicle breakdowns
                always come as an inconvenience so, from the time the vehicle is
                dropped off to the time you pick up, we will strive to do
                everything we can to take care of you and your concerns. We will
                also advise you on any upcoming or pressing issues that may have
                been unknown. We will always keep you up to date on the
                diagnosis and repair process and will never proceed with any
                work without your authorization. We pride ourselves on keeping a
                very clean facility, we follow all proper environmental
                practices, and believe that is a direct reflection of how your
                vehicle will be treated. We enjoy coming to work to provide
                friendly and honest service for the Calgary and surrounding
                area, so please consider us when your vehicle needs anything at
                all!
              </h1>
            </div>
          </div>

          {/* desktop footer */}
          <div className="flex flex-row mb-[100px] sm:space-x-0 md:space-x-10 lg:space-x-10 xl:space-x-10 items-center sm:justify-evenly md:justify-center  lg:justify-center xl:justify-center text-center">
            <div className="flex flex-col text-center">
              <h1 className="font-BankGothicMDBT justify-center  font-bankgothic text-xl md:text-4xl lg:text-4xl xl:text-4xl  text-white">
                RJ
              </h1>
              <h1 className="font-BankGothicMDBT underline justify-center sm:text-md md:text-2xl lg:text-2xl xl:text-2xl text-white ">
                Auto and Diesel Repair ©
              </h1>
            </div>

            <a href="https://www.instagram.com/rjautoanddiesel/">
              <button className="flex mt-2">
                <InstaIcon width="50" height="50" />
              </button>
            </a>

            <a href="https://www.facebook.com/people/RJ-Auto-and-Diesel-Repair/100091764995291/?mibextid=LQQJ4d">
              <button className="flex mt-2">
                <FaceBookIcon width="50" height="50" />
              </button>
            </a>
          </div>

          {/* mobile footer */}
          {/* <div className="z-50 block sm:block md:hidden lg:hidden xl:hidden sm:flex flex flex-row mb-[100px] text-lg items-center  justify-evenly  text-center">
          <div className="flex flex-col  text-center items-center">
            <h1 className="font-BankGothicMDBT text-xl justify-evenly font-bankgothic   text-white duration-500 ">
              RJ
            </h1>
            <h1 className="font-BankGothicMDBT underline justify-center   text-white ">
              Auto and Diesel Repair ©
            </h1>
          </div>

          <a href="https://www.instagram.com/rjautoanddiesel/">
            <button className="flex mt-2">
              <InstaIcon width="50" height="50" />
            </button>
          </a>

          <a href="https://www.facebook.com/people/RJ-Auto-and-Diesel-Repair/100091764995291/?mibextid=LQQJ4d">
            <button className="flex mt-2">
              <FaceBookIcon width="50" height="50" />
            </button>
          </a>
        </div> */}
        </section>

        {/* <div className='flex flex-col relative lg:mx-[15%] '>
                
                <h1 className="font-BankGothicMDBT text-center items-center mb-10 font-bankgothic sm:text-4xl md:text-7xl lg:text-7xl xl:text-7xl text-white">
                        What's New         
                </h1>

                <div className="flex flex-wrap justify-center pb-36 lg:pb-[36]">

                    <InstagramEmbed
                            data-aos='fade-down' 
                            url="https://www.instagram.com/p/Cq9WnRJJauy/" 
                            className="mx-10 my-10 sm:bg-white md:h-[755px] md:w-[500px] sm:h-[675px] sm:w-[500px] lg:h-[755px] lg:w-[500px]"
                        />


                        <InstagramEmbed
                            data-aos='fade-down'
                            url="https://www.instagram.com/p/Cq4U2LZraJN/" 
                            className="mx-10 my-10 sm:bg-white md:h-[755px] md:w-[500px] sm:h-[645px] sm:w-[500px] lg:h-[755px] lg:w-[500px]"
                        />
                </div>
                
            </div> */}
        <div className="flex flex-row fixed w-full z-50 bottom-0 md:bottom-0 lg:bottom-0 xl:bottom-0 bg-white sm:h-[80px] md:h-[80px] lg:h-[100px] xl:h-[100px] border items-center">
          <LogoCarousel />
        </div>
      </div>
    </>
  );
}
