import React from "react";

import { GoogleMap, useLoadScript, MarkerF } from "@react-google-maps/api";

const containerStyle = {
  width: "100%",
  height: "50vh",
};

const center = {
  lat: 51.10078,
  lng: -114.0382,
};

const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

export default function Map() {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey,
  });

  if (!isLoaded) return <div>Loading...</div>;

  return (
    <GoogleMap zoom={15} center={center} mapContainerStyle={containerStyle}>
      <MarkerF position={center} />
    </GoogleMap>
  );
}
