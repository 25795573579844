import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import { Link } from "react-router-dom";
import {useState, useCallback } from 'react';

const pages = ['Home', 'Services', 'Gallery', 'About', 'Contact'];

function ResponsiveAppBar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <div>
      <div className="fixed z-50 w-[100%] border-black bg-white text-black invisible md:visible lg:visible xl:visible">
        <Container maxWidth="xl">
          <Toolbar disableGutters>
                <div className="flex flex-row space-x-0.5 md:space-x-10 lg:space-x-10 items-center justify-center min-w-full invisible md:visible lg:visible xl:visible">
                  <Button onClick={handleCloseNavMenu} sx={{ my: 3, mx:3, color: 'black', }} className="hover:hover:scale-125 ">
                    <Link onClick={forceUpdate} to="/">
                      <h1 className="text-[10px] md:text-lg lg:text-lg font-BankGothicMDBT">{pages[0]}</h1>
                    </Link>
                  </Button>

                  <Button onClick={handleCloseNavMenu} sx={{ my: 3, mx:3, color: 'black', display: 'block'}} className="hover:hover:scale-125">
                    <Link onClick={forceUpdate} to="/services">
                      <h1 className="text-[10px] md:text-lg lg:text-lg font-BankGothicMDBT">{pages[1]}</h1>
                    </Link>
                  </Button>

                  <Button onClick={handleCloseNavMenu} sx={{ my: 3, mx:3, color: 'black', display: 'block'}} className="hover:hover:scale-125">
                    <Link onClick={forceUpdate} to="/gallery">
                      <h1 className="text-[10px] md:text-lg lg:text-lg font-BankGothicMDBT">{pages[2]}</h1>
                    </Link>
                  </Button>

                  <Button onClick={handleCloseNavMenu} sx={{ my: 3, mx:3, color: 'black', display: 'block',}} className="hover:hover:scale-125">
                    <Link onClick={forceUpdate} to="/about">
                    <h1 className="text-[10px] md:text-lg lg:text-lg font-BankGothicMDBT">{pages[3]}</h1>
                    </Link>
                  </Button>

                  <Button onClick={handleCloseNavMenu} sx={{ my: 3, mx:3, color: 'black', display: 'block', }} className="hover:hover:scale-125 ">
                    <Link onClick={forceUpdate} to="/contact">
                    <h1 className="text-[10px] md:text-lg lg:text-lg font-BankGothicMDBT">{pages[4]}</h1>
                    </Link>
                  </Button>
                </div>          
          </Toolbar>
        </Container>
      </div>

    {/* Mobile Nav Bar */}
      <div className="fixed z-50  w-[100%] bg-white text-black border-black visible md:invisible lg:invisible xl:invisible">
        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleOpenNavMenu}
            color="inherit"
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorElNav}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
            sx={{
              display: { xs: 'block', md: 'none' },
            }}
          > 
              <div className="flex flex-col">
                <Button onClick={handleCloseNavMenu} sx={{ my: 1, mx:1, color: 'white', }} className="hover:hover:scale-125 ">
                    <Link onClick={forceUpdate} to="/">
                      <h1 className="text-[20px] text-black font-BankGothicMDBT">{pages[0]}</h1>
                    </Link>
                </Button>

                <Button onClick={handleCloseNavMenu} sx={{ my: 1, mx:1, color: 'white', }} className="hover:hover:scale-125 ">
                    <Link onClick={forceUpdate} to="/services">
                      <h1 className="text-[20px] text-black font-BankGothicMDBT">{pages[1]}</h1>
                    </Link>
                </Button>

                <Button onClick={handleCloseNavMenu} sx={{ my: 1, mx:1, color: 'white', display: 'block'}} className="hover:hover:scale-125">
                    <Link onClick={forceUpdate} to="/gallery">
                      <h1 className="text-[20px] text-black lg:text-lg font-BankGothicMDBT">{pages[2]}</h1>
                    </Link>
                </Button>

                <Button onClick={handleCloseNavMenu} sx={{ my: 1, mx:1, color: 'white', display: 'block'}} className="hover:hover:scale-125">
                  <Link onClick={forceUpdate} to="/about">
                    <h1 className="text-[20px] text-black lg:text-lg font-BankGothicMDBT">{pages[3]}</h1>
                  </Link>
                </Button>

                <Button onClick={handleCloseNavMenu} sx={{ my: 1, mx:1, color: 'white', display: 'block'}} className="hover:hover:scale-125">
                  <Link onClick={forceUpdate} to="/contact">
                    <h1 className="text-[20px] text-black lg:text-lg font-BankGothicMDBT">{pages[4]}</h1>
                  </Link>
                </Button>
              </div>
            
          </Menu>
        </Box>
      </div>
    </div>
  );
}
export default ResponsiveAppBar;