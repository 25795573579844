import React from 'react';
import "../App.css";
import { ReactComponent as Acura } from '../images/carousel/acura.svg';
import { ReactComponent as Ford } from '../images/carousel/ford.svg';
import { ReactComponent as BMW } from '../images/carousel/bmw.svg';
import { ReactComponent as GMC } from '../images/carousel/gmc.svg';
import { ReactComponent as Honda } from '../images/carousel/honda.svg';
import { ReactComponent as Mercedes } from '../images/carousel/mercedes.svg';
import { ReactComponent as Chevrolet } from '../images/carousel/chevrolet.svg';
import { ReactComponent as Toyota } from '../images/carousel/toyota.svg';
import { ReactComponent as Volkswagen } from '../images/carousel/volkswagen.svg';
import { ReactComponent as Chrysler } from '../images/carousel/chrysler.svg';
import { ReactComponent as Kia } from '../images/carousel/kia.svg';
import { ReactComponent as Volvo } from '../images/carousel/volvo.svg';
import { ReactComponent as Landrover } from '../images/carousel/land.svg';
import { ReactComponent as Mazda } from '../images/carousel/mazda.svg';
import { ReactComponent as Porsche } from '../images/carousel/porsche.svg';
import { ReactComponent as Jeep } from '../images/carousel/jeep.svg';
import Marquee from "react-fast-marquee";
// sm:mt-[149%] md:mt-[31%] lg:mt-[37%] xl:mt-[41.5%]

export default function LogoCarousel() {

    return (
            <Marquee gradient={false} className=''  >
                
                {/* Mobile */}
                <div className='px-2 sm:block md:hidden lg:hidden xl:hidden'><Acura width="35px" height="35px"/></div>
                <div className='px-2 sm:block md:hidden lg:hidden xl:hidden'><Ford width="30px" height="30px"/></div>
                <div className='px-2 sm:block md:hidden lg:hidden xl:hidden'><BMW width="30px" height="30px"/></div>
                <div className='px-2 sm:block md:hidden lg:hidden xl:hidden'><GMC width="30px" height="30px"/></div>
                <div className='px-2 sm:block md:hidden lg:hidden xl:hidden'><Honda width="70px" height="70px"/></div>
                <div className='px-2 sm:block md:hidden lg:hidden xl:hidden'><Mercedes width="30px" height="30px"/></div>
                <div className='px-2 sm:block md:hidden lg:hidden xl:hidden'><Chevrolet width="30px" height="30px"/></div>
                <div className='px-2 sm:block md:hidden lg:hidden xl:hidden'><Toyota width="30px" height="30px"/></div>
                <div className='px-2 sm:block md:hidden lg:hidden xl:hidden'><Volkswagen width="30px" height="30px"/></div>
                <div className='px-2 sm:block md:hidden lg:hidden xl:hidden'><Chrysler width="30px" height="30px"/></div>
                <div className='px-2 sm:block md:hidden lg:hidden xl:hidden'><Kia width="30px" height="30px"/></div>
                <div className='px-2 sm:block md:hidden lg:hidden xl:hidden'><Volvo width="30px" height="30px"/></div>
                <div className='px-2 sm:block md:hidden lg:hidden xl:hidden'><Landrover width="30px" height="30px"/></div>
                <div className='px-2 sm:block md:hidden lg:hidden xl:hidden pb-10'><Mazda width="30px" height="30px"/></div>
                <div className='px-2 sm:block md:hidden lg:hidden xl:hidden'><Porsche width="30px" height="30px"/></div>
                <div className='px-2 sm:block md:hidden lg:hidden xl:hidden'><Jeep width="30px" height="30px"/></div>

                {/* Medium */}
                <div className='px-6 sm:hidden md:block lg:hidden xl:hidden'><Acura width="60px" height="60px"/></div>
                <div className='px-6 sm:hidden md:block lg:hidden xl:hidden'><Ford width="50px" height="50px"/></div>
                <div className='px-6 sm:hidden md:block lg:hidden xl:hidden'><BMW width="50px" height="50px"/></div>
                <div className='px-6 sm:hidden md:block lg:hidden xl:hidden'><GMC width="50px" height="50px"/></div>
                <div className='px-6 sm:hidden md:block lg:hidden xl:hidden'><Honda width="70px" height="70px"/></div>
                <div className='px-6 sm:hidden md:block lg:hidden xl:hidden'><Mercedes width="50px" height="50px"/></div>
                <div className='px-6 sm:hidden md:block lg:hidden xl:hidden'><Chevrolet width="50px" height="50px"/></div>
                <div className='px-6 sm:hidden md:block lg:hidden xl:hidden'><Toyota width="50px" height="50px"/></div>
                <div className='px-6 sm:hidden md:block lg:hidden xl:hidden'><Volkswagen width="50px" height="50px"/></div>
                <div className='px-6 sm:hidden md:block lg:hidden xl:hidden'><Chrysler width="50px" height="50px"/></div>
                <div className='px-6 sm:hidden md:block lg:hidden xl:hidden'><Kia width="50px" height="50px"/></div>
                <div className='px-6 sm:hidden md:block lg:hidden xl:hidden'><Volvo width="50px" height="50px"/></div>
                <div className='px-6 sm:hidden md:block lg:hidden xl:hidden'><Landrover width="50px" height="50px"/></div>
                <div className='px-6 sm:hidden md:block lg:hidden xl:hidden pb-10'><Mazda width="50px" height="50px"/></div>
                <div className='px-6 sm:hidden md:block lg:hidden xl:hidden'><Porsche width="50px" height="50px"/></div>
                <div className='px-6 sm:hidden md:block lg:hidden xl:hidden'><Jeep width="50px" height="50px"/></div>

                {/* Large */}
                <div className='px-8 sm:hidden md:hidden lg:block xl:hidden'><Acura width="60px" height="60px"/></div>
                <div className='px-8 sm:hidden md:hidden lg:block xl:hidden'><Ford width="55px" height="55px"/></div>
                <div className='px-8 sm:hidden md:hidden lg:block xl:hidden'><BMW width="55px" height="55px"/></div>
                <div className='px-8 sm:hidden md:hidden lg:block xl:hidden'><GMC width="55px" height="55px"/></div>
                <div className='px-8 sm:hidden md:hidden lg:block xl:hidden'><Honda width="70px" height="70px"/></div>
                <div className='px-8 sm:hidden md:hidden lg:block xl:hidden'><Mercedes width="55px" height="55px"/></div>
                <div className='px-8 sm:hidden md:hidden lg:block xl:hidden'><Chevrolet width="55px" height="55px"/></div>
                <div className='px-8 sm:hidden md:hidden lg:block xl:hidden'><Toyota width="55px" height="55px"/></div>
                <div className='px-8 sm:hidden md:hidden lg:block xl:hidden'><Volkswagen width="55px" height="55px"/></div>
                <div className='px-8 sm:hidden md:hidden lg:block xl:hidden'><Chrysler width="55px" height="55px"/></div>
                <div className='px-8 sm:hidden md:hidden lg:block xl:hidden'><Kia width="55px" height="55px"/></div>
                <div className='px-8 sm:hidden md:hidden lg:block xl:hidden'><Volvo width="55px" height="55px"/></div>
                <div className='px-8 sm:hidden md:hidden lg:block xl:hidden'><Landrover width="55px" height="55px"/></div>
                <div className='px-8 sm:hidden md:hidden lg:block xl:hidden pb-10'><Mazda width="55px" height="55px"/></div>
                <div className='px-8 sm:hidden md:hidden lg:block xl:hidden'><Porsche width="55px" height="55px"/></div>
                <div className='px-8 sm:hidden md:hidden lg:block xl:hidden'><Jeep width="55px" height="55px"/></div>

                {/* Extra Large */}
                <div className='px-10 sm:hidden md:hidden lg:hidden xl:block'><Acura width="80px" height="80px"/></div>
                <div className='px-10 sm:hidden md:hidden lg:hidden xl:block'><Ford width="80px" height="80px"/></div>
                <div className='px-10 sm:hidden md:hidden lg:hidden xl:block'><BMW width="80px" height="80px"/></div>
                <div className='px-10 sm:hidden md:hidden lg:hidden xl:block'><GMC width="80px" height="80px"/></div>
                <div className='px-10 sm:hidden md:hidden lg:hidden xl:block'><Honda width="70px" height="70px"/></div>
                <div className='px-10 sm:hidden md:hidden lg:hidden xl:block'><Mercedes width="80px" height="80px"/></div>
                <div className='px-10 sm:hidden md:hidden lg:hidden xl:block'><Chevrolet width="80px" height="80px"/></div>
                <div className='px-10 sm:hidden md:hidden lg:hidden xl:block'><Toyota width="80px" height="80px"/></div>
                <div className='px-10 sm:hidden md:hidden lg:hidden xl:block'><Volkswagen width="80px" height="80px"/></div>
                <div className='px-10 sm:hidden md:hidden lg:hidden xl:block'><Chrysler width="80px" height="80px"/></div>
                <div className='px-10 sm:hidden md:hidden lg:hidden xl:block'><Kia width="80px" height="80px"/></div>
                <div className='px-10 sm:hidden md:hidden lg:hidden xl:block'><Volvo width="80px" height="80px"/></div>
                <div className='px-10 sm:hidden md:hidden lg:hidden xl:block'><Landrover width="80px" height="80px"/></div>
                <div className='px-10 sm:hidden md:hidden lg:hidden xl:block pb-12'><Mazda width="80px" height="80px"/></div>
                <div className='px-10 sm:hidden md:hidden lg:hidden xl:block'><Porsche width="80px" height="80px"/></div>
                <div className='px-10 sm:hidden md:hidden lg:hidden xl:block'><Jeep width="80px" height="80px"/></div>
 
            </Marquee>
        
    )

}