import './App.css';
import { useEffect } from 'react';
import { 
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation
} from 'react-router-dom';

// import aos
import Aos from 'aos';
// import aos css
import 'aos/dist/aos.css';

import Home from './components/Home.js'
import Services from './components/Services';
import Gallery from './components/Gallery';
import About from './components/About';
import Contact from './components/Contact';
import Footer from './components/Footer';
import ScrollToTop from './components/Scrolltotop';

function App() {
  // initialize aos
  Aos.init({
    duration: 1800,
    offest: 100,
    once: true,
  })

  return (
    <div className="flex flex-col h-screen ">
      <Router>
        <ScrollToTop />
        <div className="flex flex-col mx-auto mb-auto mb-[-70px] lg:mb-[-77px] xl:mb-[-77px]"> 
          <Routes>
              <Route exact path="/" element={<Home />} />
              <Route exact path="/services" element={<Services />} />
              <Route exact path="/gallery" element={<Gallery />} />
              <Route exact path="/about" element={<About />} />
              <Route exact path="/contact" element={<Contact />} />
          </Routes>
        </div>
        {/* <Footer/> */}
      </Router>
    </div>
  )
}

export default App;
