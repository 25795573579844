import React from "react";
import NavBar from "./NavBar";
import Footer from "./Footer";
import Checklist from "../images/services/checklist.png";
import Manitenance from "../images/services/maintenance.png";
import Diagnosis from "../images/services/searching.png";
import Schedule from "../images/services/car.png";
import Engine from "../images/services/car-engine.png";
import Van from "../images/services/van.png";
import Suspension from "../images/services/suspension.png";
import Air from "../images/services/air-conditioning.png";
import Tires from "../images/services/tires.png";
import Wheels from "../images/services/wheels.png";
import Windshields from "../images/services/windscreen.png";
import { Helmet } from "react-helmet-async";

import { ReactComponent as InstaIcon } from "../images/InstaIcon.svg";
import { ReactComponent as FaceBookIcon } from "../images/FaceBookIcon.svg";

export default function About() {
  return (
    <>
      <Helmet>
        <title>About</title>
        <meta
          name="description"
          content="Rob Houck and Jacinda Osmachenko are a couple that are car enthusiasts and are both licensed red seal journeyperson automotive service technicians of nearly 15 years each!"
        />
        <link rel="canonical" href="/about" />
      </Helmet>
      <div className="flex flex-col bg-background bg-fixed bg-cover m-auto  md:h-screen lg:h-screen xl:h-screen">
        <NavBar />
        <div className="flex flex-col text-center mb-24 items-center sm:mx-[10%] md:mx-[15%] lg:mx-[15%] xl:mx-[15%]">
          <h1 className="font-BankGothicMDBT items-center sm:mt-24 md:mt-28 lg:mt-36 xl:mt-36 font-bankgothic sm:text-5xl md:text-6xl lg:text-7xl xl:text-7xl text-white ">
            About us
          </h1>

          <div className="flex flex-col justify-center ">
            <h1 className=" items-center mb-7 font-times  sm:text-x1 md:text-xl lg:text-2xl text-white ">
              Rob Houck and Jacinda Osmachenko are a couple that are car
              enthusiasts and are both licensed red seal journeyperson
              automotive service technicians of nearly 15 years each. They both
              want the best service experience and highest quality repair for
              every customer, delivered on time with complete transparency.
              These are the reasons they have decided to open their own service
              facility based completely on these few quality notes.
              <br></br>
              <br></br>
              Rob and Jacinda have been together for 14 years and have 2 young
              sons together! They are both born and raised in Calgary and have
              strong roots to their community.
            </h1>
          </div>

          <h1 className="font-BankGothicMDBT items-centerfont-bankgothic sm:mt-0 md:mt-0 lg:mt-10 xl:mt-24 sm:text-5xl md:text-6xl lg:text-7xl xl:text-7xl text-white">
            Our Location
          </h1>

          <div className="flex flex-col justify-center sm:mx-[0%] md:mx-[10%] lg:mx-[10%] xl:mx-[20%]">
            <h1 className=" items-center font-times sm:text-x1 md:text-xl lg:text-2xl text-white ">
              Location is of the utmost importance to us. We searched high and
              low for our little shop until we found a hidden gem with ample
              access off 64th avenue and McKnight Blvd at 1120 53rd Ave NE Unit
              108. The shop is comprised of 3 full-service bays. The area is
              quiet but has a vast number of amenities close by.
            </h1>
          </div>
        </div>

        {/* footer */}
        <div className="z-10">
          <div className="flex bottom-0 absolute w-[100%] border-black bg-white hidden md:block lg:block xl:block">
            <footer className="relative border-black max-h-[100px] p-6 text-[10px] md:text-lg lg:text-lg shadow  invisible md:visible lg:visible xl:visible">
              <nav className="border-black">
                <div className="flex flex-row mb-[100px] space-x-10 justify-center items-center drop-shadow-4xl text-center">
                  <div className="flex flex-col drop-shadow-4xl text-center">
                    <h1 className="font-BankGothicMDBT justify-center  font-bankgothic text-2xl  text-black duration-500 ">
                      RJ
                    </h1>
                    <h1 className="font-BankGothicMDBT underline justify-center text-xl  text-black ">
                      Auto and Diesel Repair ©
                    </h1>
                  </div>

                  <div className="flex">
                    <a href="https://www.instagram.com/rjautoanddiesel/">
                      <button className="">
                        <InstaIcon width="50" height="50" />
                      </button>
                    </a>
                  </div>

                  <div className="flex">
                    <a href="https://www.facebook.com/people/RJ-Auto-and-Diesel-Repair/100091764995291/?mibextid=LQQJ4d">
                      <button className="">
                        <FaceBookIcon width="50" height="50" />
                      </button>
                    </a>
                  </div>
                </div>
              </nav>
            </footer>
          </div>

          {/* Mobile Footer */}
          <div className="bottom-0 min-h-[70px] bg-white block md:hidden lg:hidden xl:hidden">
            <footer className="relative h-20 uppercase border-black font-BankGothicMDBT p-4 text-[10px] md:text-lg lg:text-lg p-4">
              <nav className="">
                <ul className="flex justify-evenly ">
                  <div className="flex flex-col drop-shadow-4xl text-center">
                    <h1 className="font-BankGothicMDBT justify-center  font-bankgothic text-xl  text-black duration-500 ">
                      RJ
                    </h1>
                    <h1 className="font-BankGothicMDBT underline justify-center font-bankgothic  text-black ">
                      Auto and Diesel Repair
                    </h1>
                  </div>
                  <li>
                    <a href="https://www.instagram.com/rjautoanddiesel/">
                      <button className="lg:invisible ">
                        <InstaIcon width="40" height="40" />
                      </button>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.facebook.com/people/RJ-Auto-and-Diesel-Repair/100091764995291/?mibextid=LQQJ4d">
                      <button className="lg:invisible">
                        <FaceBookIcon width="40" height="40" />
                      </button>
                    </a>
                  </li>
                </ul>
              </nav>
            </footer>
          </div>
        </div>
      </div>
    </>
  );
}
